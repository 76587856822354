/*import { auth } from "./firebaseConfig";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      return {
        success: true,
        email: user.email,
        uid: user.uid,
      };
  } catch (error) {
    console.error("Google-kirjautumisvirhe:", error);
    return {
      success: false,
    };
  }
}*/
import { auth } from "./firebaseConfig";
import { GoogleAuthProvider, signInWithRedirect, getRedirectResult } from "firebase/auth";

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  try {
    await signInWithRedirect(auth, provider);
    return { success: true };
  } catch (error) {
    console.error("Google-kirjautumisvirhe:", error);
    return {
      success: false,
    };
  }
}

export async function handleGoogleSignInRedirect() {
  try {
    const result = await getRedirectResult(auth);
    if (!result) {
      return { success: false };
    }
    const user = result.user;
    return {
      success: true,
      email: user.email,
      uid: user.uid,
    };
  } catch (error) {
    console.error("Google-kirjautumisvirhe:", error);
    return {
      success: false,
    };
  }
}
