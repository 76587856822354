import React, { useState, useEffect } from "react";

function ResponseCounter(props) {
  const [count, setCount] = useState(0);

  const [changeValues, setChangeValues] = useState(false);


  const getDelayValues = (change, support) => [
    { start: 0, end: 5, duration: change ? (support ? (2 / 4) : (3 / 5)) : (support ? 2 : 3) },
    { start: 5, end: 8, duration: change ? (support ? (1 / 4) : (2 / 5)) : (support ? 1 : 2) },
    { start: 8, end: 17, duration: change ? (support ? (2 / 4) : (3 / 5)) : (support ? 2 : 3) },
    { start: 17, end: 25, duration: change ? (support ? (4 / 4) : (4 / 5)) : (support ? 4 : 4) },
    { start: 25, end: 35, duration: change ? (support ? (3 / 4) : (3 / 5)) : (support ? 3 : 3) },
    { start: 35, end: 50, duration: change ? (support ? (5 / 4) : (8 / 5)) : (support ? 5 : 8) },
    { start: 50, end: 60, duration: change ? (support ? (5 / 4) : (8 / 5)) : (support ? 5 : 8) },
    { start: 60, end: 70, duration: change ? (support ? (5 / 4) : (8 / 5)) : (support ? 5 : 8) },
    { start: 70, end: 80, duration: change ? (support ? (5 / 4) : (8 / 5)) : (support ? 5 : 8) },
    { start: 85, end: 90, duration: change ? (support ? (12 / 5) : (13 / 6)) : (support ? 12 : 13) },
    { start: 90, end: 95, duration: change ? (support ? (13 / 4) : (17 / 5)) : (support ? 13 : 17) },
    { start: 95, end: 100, duration: change ? (support ? (13 / 5) : (17 / 6)) : (support ? 13 : 17) },
    
  ];

  
  useEffect(() => {
    if (props.valmis.current) {
    setChangeValues(true);
    }
    
  }, [props.valmis.current, props.support]);

  const totalDuration = 50;

  useEffect(() => {
    if (count >= 100) {
      // Call the props function here
      props.analy();
    }
  }, [count]);
  
  useEffect(() => {
    const delayValues = getDelayValues(changeValues,props.support);
    let remainingTime = totalDuration;
    let timeoutIds = [];
  
    delayValues.forEach((delay, index) => {
      const range = delay.end - delay.start;
      const interval = delay.duration / range;
  
      for (let i = 0; i < range; i++) {
        remainingTime -= interval;
        const timeoutId = setTimeout(() => {
          setCount((prevCount) => {
            const newCount = prevCount + 1;
  
            if (newCount > 100) {
              return 100;
            }
  
            return newCount;
          });
        }, totalDuration * 1000 - remainingTime * 1000);
  
        timeoutIds.push(timeoutId);
      }
    });
  
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [changeValues]);

  return (
  
      <span>  {count}/100%</span>
   
  );
};

export default ResponseCounter;

/*
 const getDelayValues = (change) => [
    { start: 0, end: 5, duration: change ? (3 / 4) : 3 },
    { start: 5, end: 8, duration: change ? (1 / 4) : 1 },
    { start: 8, end: 17, duration: change ? (4 / 4) : 4 },
    { start: 17, end: 25, duration: change ? (7 / 4) : 7 },
    { start: 25, end: 35, duration: change ? (5 / 4) : 5 },
    { start: 35, end: 50, duration: change ? (10 / 4) : 10 },
    { start: 50, end: 60, duration: change ? (10 / 4) : 10 },
    { start: 60, end: 70, duration: change ? (10 / 4) : 10 },
    { start: 70, end: 80, duration: change ? (10 / 4) : 10 },//60-tähän vois mennä 50 sekkaa
    { start: 85, end: 90, duration: change ? (14 / 5) : 14 },
    { start: 90, end: 95, duration: change ? (13 / 4) : 13 }, // Lisätty puuttuva alue
    { start: 95, end: 100, duration: change ? (13 / 5) : 13 },//100
  ];



*/