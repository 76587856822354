import './App.css';
import { useEffect, useRef, useState } from 'react'

function MotInterview(props) {

   /* const [motQuestions, setMotQuestions] = useState([
        "What are some positive aspects of your work?",
        "What´s different about your job now from when you started?",
        "Can you tell me more about what you do at work?",
        "Has anything happened at work recently?",
        "How do your colleagues affect the way you work?",
        "How would you describe your relationship with your colleagues?",
        "Could you tell me about a time when you had a great idea at your job?",
        "What do you do to manage your time efficiently while on the job?",
        "How do your managers help motivate you while you work?",
        "What do you think motivates you most at work?",
        "What brought you here today?",
        "How can I help you feel more comfortable at work?",
        "Why is your work important to you?",
        "What attracted you to your occupation?",
        "What methods of relaxing have helped you previously?",
        "What was it like before you started feeling this way about your work?",
        "What do you think might happen if you don&#39;t make a change?",
        "What´s the best part of your day?",

    ]);*/
        const motQuestionsUS = ([
          "What are some positive aspects of your work?",
          "What´s different about your job now from when you started?",
          "Can you tell me more about what you do at work?",
          "Has anything happened at work recently?",
          "How do your colleagues affect the way you work?",
          "How would you describe your relationship with your colleagues?",
          "Could you tell me about a time when you had a great idea at your job?",
          "What do you do to manage your time efficiently while on the job?",
          "How do your managers help motivate you while you work?",
          "What do you think motivates you most at work?",
          "What brought you here today?",
          "How can I help you feel more comfortable at work?",
          "Why is your work important to you?",
          "What attracted you to your occupation?",
          "What methods of relaxing have helped you previously?",
          "What was it like before you started feeling this way about your work?",
          "What do you think might happen if you don´t make a change?",
          "What´s the best part of your day?",
          "What makes you feel supported at work?",
          "What have you done at work that makes you proud?",
          "How might you improve your work?",
          "What attempts have you already made to improve your situation?",
          "How could changing the way you work make things different?",
          "What changes would help you achieve your goals?",
          "How has your job helped you improve professionally or personally?",
          "Could you describe a time when you discovered a new method or process for doing your work?",
          "What were your goals when seeking this job?",
          "How have your goals changed?",
          "How does the progress you've made so far make you feel?",
          "What inspires you to keep working towards your goals?",
          "How do you maintain a work-life balance?",
          "What strategies do you use to stay focused on your goals?",
          "How do you define success in your current role?",
          "Can you share an example of when you faced a challenge and found motivation to overcome it?",
          "How do you stay motivated when you face criticism or negative feedback?",
          "What activities or hobbies help you recharge outside of work?",
          "Can you describe a situation where you had to motivate a colleague or team member?",
          "How do you seek out opportunities for personal growth and development?",
          "What is your favorite aspect of your job and why?",
          "How do you stay motivated when working on long-term projects?",
          "How do you approach goal-setting, both personally and professionally?",
          "How has your job allowed you to make a positive impact on others?",
          "Can you share a story about a time when you felt particularly motivated or inspired at work?",
          "How do you stay motivated when working in a team with diverse personalities and goals?",
          "How do you handle setbacks or disappointments in your work?",
          "What role does job satisfaction play in keeping you motivated?",
          "How do you approach learning new skills or acquiring new knowledge for your job?",
          "Can you share an example of when you took initiative at work to achieve a positive outcome?",
          "How do you find motivation in situations where the work may be mundane or repetitive?",
          "How do you measure your success and progress in your career?",
      ])
  
      const motQuestionsFin = ([
        "Mikä on työssäsi positiivista?",
        "Mikä on erilaista työssäsi nyt verrattuna aloittaessasi?",
        "Voisitko kertoa lisää siitä, mitä teet työssäsi?",
        "Onko työssäsi tapahtunut viime aikoina jotain?",
        "Miten kollegasi vaikuttavat työskentelytapaasi?",
        "Miten kuvailisit suhdettasi kollegoihisi?",
        "Voisitko kertoa jonkin kerran, jolloin keksit hienon idean työssäsi?",
        "Mitä teet ajankäytön hallitsemiseksi tehokkaasti työssä?",
        "Miten esimiehesi auttavat motivoimaan sinua työskentelyn aikana?",
        "Mikä mielestäsi motivoi sinua eniten työssä?",
        "Mikä toi sinut tänne tänään?",
        "Kuinka voin auttaa sinua tuntemaan olosi mukavammaksi työssä?",
        "Miksi työsi on sinulle tärkeää?",
        "Mikä houkutteli sinut ammattiisi?",
        "Mitkä rentoutumismenetelmät ovat auttaneet sinua aiemmin?",
        "Miltä sinusta tuntui ennen kuin aloit tuntea näin työstäsi?",
        "Mitä luulet tapahtuvan, jos et tee muutosta?",
        "Mikä on päiväsi paras hetki?","Mikä saa sinut tuntemaan olevasi tuettu työssäsi?",
        "Mitä olet tehnyt työssä, josta olet ylpeä?",
        "Kuinka voisit parantaa työskentelyäsi?",
        "Mitä yrityksiä olet jo tehnyt parantaaksesi tilannettasi?",
        "Kuinka työskentelytapasi muuttaminen voisi tehdä asiat erilaisiksi?",
        "Mitkä muutokset auttaisivat sinua saavuttamaan tavoitteesi?",
        "Kuinka työsi on auttanut sinua kehittymään ammatillisesti tai henkilökohtaisesti?",
        "Voisitko kuvailla tilannetta, jolloin löysit uuden menetelmän tai prosessin työsi tekemiseen?",
        "Mitkä olivat tavoitteesi hakiessasi tätä työpaikkaa?",
        "Kuinka tavoitteesi ovat muuttuneet?",
        "Kuinka tähänastinen edistymisesi saa sinut tuntemaan?",
        "Mikä inspiroi sinua jatkamaan tavoitteiden saavuttamista?",
        "Kuinka pidät yllä työ- ja vapaa-ajan tasapainoa?",
        "Mitä strategioita käytät pysyäksesi keskittyneenä tavoitteisiisi?",
        "Kuinka määrittelet menestyksen nykyisessä roolissasi?",
        "Voisitko kertoa esimerkin, jolloin kohtasit haasteen ja löysit motivaation voittaa se?",
        "Kuinka pysyt motivoituneena, kun kohtaat kritiikkiä tai negatiivista palautetta?",
        "Mitkä harrastukset tai aktiviteetit auttavat sinua lataamaan akkuja työn ulkopuolella?",
        "Voisitko kuvailla tilannetta, jossa sinun piti motivoida työkaveria tai tiimin jäsentä?",
        "Kuinka etsit mahdollisuuksia henkilökohtaiseen kasvuun ja kehittymiseen?",
        "Mikä on lempipuolesi työssäsi ja miksi?",
        "Kuinka pysyt motivoituneena työskennellessäsi pitkäkestoisissa projekteissa?",
        "Kuinka lähestyt tavoitteiden asettamista, sekä henkilökohtaisesti että ammatillisesti?",
        "Kuinka työsi on mahdollistanut sinulle positiivisen vaikutuksen muihin ihmisiin?",
        "Voisitko kertoa tarinan ajasta, jolloin tunsit olevasi erityisen motivoitunut tai inspiroitunut työssä?",
        "Kuinka pysyt motivoituneena työskennellessäsi tiimissä, jossa on monenlaisia persoonallisuuksia ja tavoitteita?",
        "Kuinka käsittelet takaiskuja tai pettymyksiä työssäsi?",
        "Mikä rooli työtyytyväisyydellä on motivaatiosi ylläpitämisessä?",
        "Kuinka lähestyt uusien taitojen oppimista tai uuden tiedon hankkimista työtäsi varten?",
        "Voisitko kertoa esimerkin, jolloin otit aloitteen työssä saavuttaaksesi positiivisen tuloksen?",
        "Kuinka löydät motivaatiota tilanteissa, joissa työ saattaa olla arkista tai toistuvaa?",
        "Kuinka mittaat menestystäsi ja edistymistäsi urallasi?",
    ])
  
    const motQuestionsEsp = ([
      "¿Cuáles son los aspectos positivos de tu trabajo?",
      "¿Qué es diferente en tu trabajo ahora en comparación con cuando comenzaste?",
      "¿Podrías contarme más sobre lo que haces en el trabajo?",
      "¿Ha ocurrido algo en el trabajo recientemente?",
      "¿Cómo afectan tus colegas la forma en que trabajas?",
      "¿Cómo describirías tu relación con tus colegas?",
      "¿Podrías contarme acerca de una vez que tuviste una gran idea en tu trabajo?",
      "¿Qué haces para administrar tu tiempo de manera eficiente en el trabajo?",
      "¿Cómo te ayudan tus gerentes a motivarte mientras trabajas?",
      "¿Qué crees que te motiva más en el trabajo?",
      "¿Qué te trajo aquí hoy?",
      "¿Cómo puedo ayudarte a sentirte más cómodo en el trabajo?",
      "¿Por qué es importante tu trabajo para ti?",
      "¿Qué te atrajo a tu ocupación?",
      "¿Qué métodos de relajación te han ayudado anteriormente?",
      "¿Cómo era antes de que comenzaras a sentirte así acerca de tu trabajo?",
      "¿Qué crees que podría pasar si no haces un cambio?",
      "¿Cuál es la mejor parte de tu día?",
      "¿Qué te hace sentir apoyado en el trabajo?", 
      "¿Qué has hecho en el trabajo que te enorgullece?", 
      "¿Cómo podrías mejorar tu trabajo?", 
      "¿Qué intentos ya has realizado para mejorar tu situación?", 
      "¿Cómo podría cambiar la forma en que trabajas para que las cosas sean diferentes?", 
      "¿Qué cambios te ayudarían a alcanzar tus objetivos?", 
      "¿Cómo te ha ayudado tu trabajo a mejorar profesional o personalmente?", 
      "¿Podrías describir una vez que descubriste un nuevo método o proceso para hacer tu trabajo?", 
      "¿Cuáles eran tus objetivos al buscar este trabajo?",
       "¿Cómo han cambiado tus objetivos?", 
       "¿Cómo te hace sentir el progreso que has logrado hasta ahora?", 
       "¿Qué te inspira a seguir trabajando hacia tus objetivos?", 
       "¿Cómo mantienes un equilibrio entre el trabajo y la vida personal?", 
       "¿Qué estrategias utilizas para mantenerte enfocado en tus objetivos?", 
       "¿Cómo defines el éxito en tu rol actual?", 
       "¿Puedes compartir un ejemplo de cuando enfrentaste un desafío y encontraste motivación para superarlo?", 
       "¿Cómo te mantienes motivado cuando enfrentas críticas o comentarios negativos?", 
       "¿Qué actividades o pasatiempos te ayudan a recargarte fuera del trabajo?", 
       "¿Puedes describir una situación en la que tuviste que motivar a un colega o miembro del equipo?", 
       "¿Cómo buscas oportunidades para el crecimiento y desarrollo personal?", 
       "¿Cuál es tu aspecto favorito de tu trabajo y por qué?", 
       "¿Cómo te mantienes motivado cuando trabajas en proyectos a largo plazo?", 
       "¿Cómo abordas la establecimiento de metas, tanto personal como profesionalmente?", 
       "¿Cómo te ha permitido tu trabajo tener un impacto positivo en otros?", 
       "¿Puedes compartir una historia sobre un momento en el que te sentiste particularmente motivado o inspirado en el trabajo?", 
       "¿Cómo te mantienes motivado cuando trabajas en un equipo con personalidades y objetivos diversos?", 
       "¿Cómo manejas los contratiempos o decepciones en tu trabajo?", 
       "¿Qué papel desempeña la satisfacción laboral en mantenerte motivado?", 
       "¿Cómo abordas el aprendizaje de nuevas habilidades o adquisición de nuevos conocimientos para tu trabajo?", 
       "¿Puedes compartir un ejemplo de cuando tomaste la iniciativa en el trabajo para lograr un resultado positivo?", 
       "¿Cómo encuentras motivación en situaciones donde el trabajo puede ser monótono o repetitivo?", 
       "¿Cómo mides tu éxito y progreso en tu carrera?"
  ])
  const [motQuestions, setMotQuestions] =useState([]);
  const [title, setTitle] = useState("");
  
    /*if (props.lan === "fi"){
      //setFitQuestions(fitQuestionsFin);
      setFitQuestions(fitQuestionsFin);
      
    }
    else if (props.lan === "en-US"){
      setFitQuestions(fitQuestionsUS);
      
    }
    else if (props.lan === "es-ES"){
      setFitQuestions(fitQuestionsEsp);
      
    }*/
  
    useEffect(() => {
      //Mejores preguntas de entrevista de aptitud
      if (props.lan === "fi"){
      //setFitQuestions(fitQuestionsFin);
      setMotQuestions(motQuestionsFin);
      setTitle("Parhaat motivaatiokysymykset");
        
      }
      else if (props.lan === "en-US"){
        setMotQuestions(motQuestionsUS);
        setTitle("Top Motivation\n Questions");
      }
      else if (props.lan === "es-ES"){
        setMotQuestions(motQuestionsEsp);
        setTitle("Las mejores preguntas\n de motivación");
      }
    }, []);
    
    if (motQuestions.length === 0) {
      return (
        <div className="Right">
          <p></p>
        </div>
      );
    }else {
      return (
        <div className="Right">
         <div style={{maxWidth:"80%", textAlign:"center", marginLeft:"10%", fontSize:"0.9em"}}>
         <h1 style={{whiteSpace: 'pre-wrap'}} className='QuestionTitle'>{title}</h1></div>
          {motQuestions.map((question, index) =>
            <div key={index} className='Questions'>
               <p onClick={() => props.function(motQuestions[index])}  className='Question' key={index}>{question}</p>
            </div>
          )}
        </div>
      );
    }
  
}

export default MotInterview;