import './App.css';
import { useEffect, useRef, useState } from 'react'
import Ylin from "./ylin.png";
import Keski from "./keski.png";
import Alin from "./alin.png";
import Logo from "./logo.png";
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import FeedbackModal from "./feedback";

function Main() {
  

  return (
    <div style={{ backgroundColor: "#142e64" }}>
      <div className='Header'>
        <h1 /*style={{ fontSize: "4em", color: "White", marginTop: "2vh", lineHeight:"0.9em" }}*/ className='MainTitle'>Interview Simulator</h1>
        
      </div>
      <img className='Logo' src={Logo} alt="Logo"></img>
      <div className="Left">
       
          <img className='MainPics' src={Ylin} alt="Upper jpg" />
        
        
          <img className='MainPics' src={Keski} alt="Centre jpg" />
        
        
          <img className='MainPics' src={Alin} alt="Bottom jpg" />
        
      </div>
    
    </div>
  );
}

export default Main;