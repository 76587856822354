import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react'

const FeedbackModal = ({
  isOpen,
  handleCloseModal,
  feedback,
  handleRatingChange,
  handleCommentsChange,
  handleSubmit,
  lan
}) => {
  

  const [words, setWords] = useState({
    title: "Give feedback",
    rating: "Rating Scale",
    though: "Share Your Thoughts",
    submit: "Submit"
  });

  useEffect(() => {
    if (lan === "fi") {
      setWords({
        title: "Anna palautetta",
        rating: "Arvosana",
        though: "Kommentit",
        submit: "Lähetä"
      });
    } else if (lan === "en-US") {
      setWords({
        title: "Give feedback",
        rating: "Rating Scale",
        though: "Share Your Thoughts",
        submit: "Submit"
      });
    } else if (lan === "es-ES") {
      setWords({
        title: "Dar retroalimentación",
        rating: "Escala de calificación",
        though: "Comparte tus pensamientos",
        submit: "Enviar"
      });
    }
  }, [lan]);

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      contentLabel="Esimerkki modaali"
      shouldCloseOnOverlayClick={false}
      className="Feedback"
      
    >
      <div className='closebar'>
        <span className='CloseFeed' onClick={handleCloseModal}>x</span>
      </div>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop:"-1vh",
          
         
        }}
      >
        <h3>{words.title}</h3>
        <Row
          className="justify-content-md-center"
          style={{
            width: '90%',
            height: '45vh',
            display: 'flex',
            justifyContent: 'center',
            
            
          }}
        >
          <Col>
            <Card
              style={{
                width: '100%',
                height: '50vh',
                marginBottom: '5vh',
                marginTop:"5vh"
              }}
            >
              <Card.Body
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    controlId="rating"
                    style={{
                      justifyContent: 'center',
                      height: '12vh',
                    }}
                  >
                    <Form.Label>{words.rating}</Form.Label>
                    <Form.Control
                      as="select"
                      value={feedback.rating}
                      onChange={handleRatingChange}
                      style={{
                        width: '8vh',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '0vh',
                        borderRadius: '1px',
                        textAlign: 'center',
                       
                      }}
                    >
                      {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="comments">
                    <Form.Label>{words.though}</Form.Label>
                    <Form.Control
                      as="textarea"
                      laceholder="Give us feedback and simultaneously enter the contest where we're giving away free premium memberships to lucky winners!"
                      value={feedback.comments}
                      onChange={handleCommentsChange}
                      style={{
                        height: '20vh',
                        width: '80%',
                        marginLeft: '10%',
                        
                      }}
                    />
                  </Form.Group>
                  <Button
                    className='FeedbackButton'
                    variant="primary"
                    type="submit"
                  >
                    {words.submit}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

FeedbackModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired,
  handleRatingChange: PropTypes.func.isRequired,
  handleCommentsChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  lan: PropTypes.string.isRequired,
  };
  
  export default FeedbackModal;