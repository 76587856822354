import './App.css';
import { useEffect, useRef, useState } from 'react'
import { FaBeer } from 'react-icons/fa';

function Volume(props) {

  const canvasRef = useRef(null);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);


  useEffect(() => {
    
    async function getAudioStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const context = new AudioContext();
        const source = context.createMediaStreamSource(stream);
        const analyserNode = context.createAnalyser();
  
        source.connect(analyserNode);
        setAudioContext(context);
        setAnalyser(analyserNode);
      } catch (error) {
        console.error('Error accessing the microphone:', error);
      }
    }
  
    getAudioStream();
    

 
  }, []);

  useEffect(() => {
      
    if (analyser && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const draw = () => {
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyser.getByteFrequencyData(dataArray);
        const volume = dataArray.reduce((a, b) => a + b) / bufferLength;
        const width = canvas.width;
        const height = canvas.height;
        ctx.clearRect(0, 0, width, height);
        ctx.fillStyle = 'rgb(0, 255, 0)';
        ctx.fillRect(0, 0, (volume / 100) * width, height);
        requestAnimationFrame(draw);
      };
  
      draw();
    }
  }, [analyser]);

    return (
      <div className="Volume">
        <canvas  ref={canvasRef} className="Canvas"/>
      </div>
    );
  

  
}

export default Volume;