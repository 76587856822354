import './App.css';
import { useEffect, useRef, useState } from 'react'
import { FaBeer } from 'react-icons/fa';

function FitInterview(props) {

    const fitQuestionsUS = ([
        "Tell me about yourself.",
        "What are your greatest strengths?",
        "What are your greatest weaknesses?",
        "Why do you want to work for our company?",
        "What do you know about our company?",
        "Why should we hire you?",
        "What are your career goals?",
        "Where do you see yourself in five years?",
        "How do you handle stress or pressure?",
        "Describe a difficult situation you faced and how you handled it.",
        "Tell me about a time when you had to make a difficult decision.",
        "How do you handle conflict in the workplace?",
        "Describe your leadership style.",
        "What motivates you?",
        "How do you stay organized and manage your time?",
        "Are you a team player?",
        "What is your greatest accomplishment?",
        "Tell me about a time when you failed.",
        "How do you handle criticism?",
        "What do you do when you don´t agree with your boss?",
        "How do you handle competing priorities?",
        "How do you learn from your mistakes?",
        "How do you adapt to change?",
        "What is your ideal work environment?",
        "What type of management style do you prefer?",
        "What is your favorite project you've worked on?",
        "What are your salary expectations?",
        "How do you stay up-to-date in your industry?",
        "Describe a time when you had to work under tight deadlines.",
        "How do you balance work and personal life?",
        "What are your hobbies or interests outside of work?",
        "How do you build relationships with coworkers?",
        "How do you handle office politics?",
        "How do you approach problem-solving?",
        "What do you consider your most significant professional achievement?",
        "Describe a time when you had to persuade someone.",
        "How do you handle unexpected obstacles?",
        "How do you prioritize tasks?",
        "What do you like most about your current job?",
        "What do you dislike most about your current job?",
        "Why are you looking for a new job?",
        "What is your dream job?",
        "How would your coworkers describe you?",
        "How would your boss describe you?",
        "Describe a time when you went above and beyond for a project or client.",
        "What are your expectations for a new role?",
        "How do you handle working with difficult coworkers?",
        "What are your long-term career aspirations?",
        "How do you stay motivated in challenging situations?",
        "Do you have any questions for us?"
    ])

    const fitQuestionsFin = ([
      "Kerro itsestäsi.",
      "Mikä ovat suurimmat vahvuutesi?",
      "Mikä ovat suurimmat heikkoutesi?",
      "Miksi haluat työskennellä yrityksessämme?",
      "Mitä tiedät yrityksestämme?",
      "Miksi meidän pitäisi palkata sinut?",
      "Mitkä ovat uratavoitteesi?",
      "Missä näet itsesi viiden vuoden kuluttua?",
      "Kuinka käsittelet stressiä tai painetta?",
      "Kuvaile vaikeaa tilannetta, jonka kohtasit ja miten selviydyit siitä.",
      "Kerro tilanteesta, jolloin sinun piti tehdä vaikea päätös.",
      "Kuinka käsittelet konflikteja työpaikalla?",
      "Kuvaile johtamistyyliäsi.",
      "Mikä motivoi sinua?",
      "Kuinka pysyt järjestäytyneenä ja hallitset aikaasi?",
      "Oletko tiimipelaaja?",
      "Mikä on suurin saavutuksesi?",
      "Kerro tilanteesta, jossa epäonnistuit.",
      "Kuinka käsittelet kritiikkiä?",
      "Miten toimit, kun et ole samaa mieltä esimiehesi kanssa?",
      "Kuinka käsittelet kilpailevia prioriteetteja?",
      "Miten opit virheistäsi?",
      "Miten sopeudut muutoksiin?",
      "Mikä on ihanteellinen työympäristösi?",
      "Minkä tyyppistä johtamistapaa suosit?",
      "Mikä on suosikki projekti, johon olet osallistunut?",
      "Mitkä ovat palkkatoiveesi?",
      "Miten pysyt ajan tasalla alallasi?",
      "Kuvaile tilannetta, jossa sinun piti työskennellä tiukkojen aikarajojen alla.",
      "Miten tasapainotat työn ja henkilökohtaisen elämän?",
      "Mitkä ovat harrastuksesi tai kiinnostuksenkohteesi työn ulkopuolella?",
      "Miten rakennat suhteita työkavereihin?",
      "Miten käsittelet toimistopolitiikkaa?",
      "Miten lähestyt ongelmanratkaisua?",
      "Mitä pidät merkittävimpänä ammatillisena saavutuksenasi?",
      "Kuvaile tilannetta, jossa jouduit vakuuttamaan jonkun.",
      "Miten käsittelet odottamattomia esteitä?",
      "Miten priorisoit tehtäviä?",
      "Mitä pidät eniten nykyisestä työstäsi?",
      "Mitä pidät vähiten nykyisestä työstäsi?",
      "Miksi etsit uutta työtä?",
      "Mikä on unelmatyösi?",
      "Miten työkaverisi kuvailisivat sinua?",
      "Miten esimiehesi kuvailisi sinua?",
      "Kuvaile tilannetta, jossa menit yli ja edelle projektin tai asiakkaan vuoksi.",
      "Mitkä ovat odotuksesi uudelle roolille?",
      "Miten käsittelet vaikeiden työkavereiden kanssa työskentelyä?",
      "Mitkä ovat pitkän aikavälin uratavoitteesi?",
      "Miten pysyt motivoituneena haastavissa tilanteissa?",
      "Onko sinulla kysyttävää meiltä?",
  ])

  const fitQuestionsEsp = ([
    "Cuéntame sobre ti.",
    "¿Cuáles son tus mayores fortalezas?",
    "¿Cuáles son tus mayores debilidades?",
    "¿Por qué quieres trabajar para nuestra empresa?",
    "¿Qué sabes de nuestra empresa?",
    "¿Por qué deberíamos contratarte?",
    "¿Cuáles son tus objetivos profesionales?",
    "¿Dónde te ves en cinco años?",
    "¿Cómo manejas el estrés o la presión?",
    "Describe una situación difícil que enfrentaste y cómo la manejaste.",
    "Cuéntame sobre una vez en que tuviste que tomar una decisión difícil.",
    "¿Cómo manejas los conflictos en el lugar de trabajo?",
    "Describe tu estilo de liderazgo.",
    "¿Qué te motiva?",
    "¿Cómo te mantienes organizado y gestionas tu tiempo?",
    "¿Eres un jugador de equipo?",
    "¿Cuál es tu mayor logro?",
    "Cuéntame sobre una vez en que fallaste.",
    "¿Cómo manejas las críticas?",
    "¿Qué haces cuando no estás de acuerdo con tu jefe?",
    "¿Cómo manejas las prioridades en competencia?",
    "¿Cómo aprendes de tus errores?",
    "¿Cómo te adaptas al cambio?",
    "¿Cuál es tu entorno de trabajo ideal?",
    "¿Qué tipo de estilo de gestión prefieres?",
    "¿Cuál es tu proyecto favorito en el que has trabajado?",
    "¿Cuáles son tus expectativas salariales?",
    "¿Cómo te mantienes actualizado en tu industria?",
    "Describe un momento en el que tuviste que trabajar bajo plazos ajustados.",
    "¿Cómo equilibras el trabajo y la vida personal?",
    "¿Cuáles son tus aficiones o intereses fuera del trabajo?",
    "¿Cómo construyes relaciones con compañeros de trabajo?",
    "¿Cómo manejas la política de oficina?",
    "¿Cómo abordas la resolución de problemas?",
    "¿Cuál consideras que es tu logro profesional más significativo?",
    "Describe una vez que tuviste que persuadir a alguien.",
    "¿Cómo manejas obstáculos inesperados?",
    "¿Cómo priorizas las tareas?",
    "¿Qué es lo que más te gusta de tu trabajo actual?",
    "¿Qué es lo que menos te gusta de tu trabajo actual?",
    "¿Por qué estás buscando un nuevo empleo?",
    "¿Cuál es tu trabajo soñado?",
    "¿Cómo te describirían tus compañeros de trabajo?",
    "¿Cómo te describiría tu jefe?",
    "Describe una vez que diste un paso más allá por un proyecto o cliente.",
    "¿Cuáles son tus expectativas para un nuevo puesto?",
    "¿Cómo manejas trabajar con compañeros de trabajo difíciles?",
    "¿Cuáles son tus aspiraciones profesionales a largo plazo?",
    "¿Cómo te mantienes motivado en situaciones desafiantes?",
    "¿Tienes alguna pregunta para nosotros?"
    ])
const [fitQuestions, setFitQuestions] =useState([]);
const [title, setTitle] = useState("");

  /*if (props.lan === "fi"){
    //setFitQuestions(fitQuestionsFin);
    setFitQuestions(fitQuestionsFin);
    
  }
  else if (props.lan === "en-US"){
    setFitQuestions(fitQuestionsUS);
    
  }
  else if (props.lan === "es-ES"){
    setFitQuestions(fitQuestionsEsp);
    
  }*/

  useEffect(() => {
    //Mejores preguntas de entrevista de aptitud
    if (props.lan === "fi"){
    //setFitQuestions(fitQuestionsFin);
    setFitQuestions(fitQuestionsFin);
    setTitle("Parhaat soveltuvuuskysymykset ");
      
    }
    else if (props.lan === "en-US"){
      setFitQuestions(fitQuestionsUS);
      setTitle("Top Fit\n Questions");
    }
    else if (props.lan === "es-ES"){
      setFitQuestions(fitQuestionsEsp);
      setTitle("Las mejores preguntas\n de aptitud");
    }
  }, []);
  
  if (fitQuestions.length === 0) {
    return (
      <div className="Centre">
        <p></p>
      </div>
    );
  }else {
    return (
      <div className="Centre">
        <div style={{maxWidth:"80%", textAlign:"center", marginLeft:"10%", fontSize:"0.9em"}}>
        <h1 style={{whiteSpace: 'pre-wrap'}} className='QuestionTitle'>{title}</h1></div>
        {fitQuestions.map((question, index) =>
          <div key={index} className='Questions'>
             <p onClick={() => props.function(fitQuestions[index])}  className='Question' key={index}>{question}</p>
          </div>
        )}
      </div>
    );
  }

  
}

export default FitInterview;