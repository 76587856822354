import { useState, useEffect } from 'react'
import { Navbar, Nav, Container, Modal } from 'react-bootstrap';
import { VscFeedback, VscSignOut } from "react-icons/vsc";
import {FaMoneyCheckAlt} from "react-icons/fa"
import { AiOutlineHome } from "react-icons/ai";
import {FiSettings} from "react-icons/fi";
import {BsQuestionOctagon} from "react-icons/bs";

const MyNavbar = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showPurch, setShowPurch] = useState(false);

  useEffect(() => {
    if (props.kredits == 0){
      setShowPurch(true);
    }else {
      setShowPurch(false);
    }
  }, [props.kredits]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const openLink = () => {
 
    
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('credits');
      window.location.href = 'https://www.lassialbin.com/the-ai-powered-interview-simulator';
  };

  const openLink2 = () => {
  
      window.open('https://www.lassialbin.com/tyohaastattelusimulaattori', '_blank'); 
    
  };
  return (
    <Navbar bg="dark" variant="dark" expand="md" style={{ paddingLeft: 0, paddingRight: 0 }} className='NavBar'>
      <Container fluid>
        <Navbar.Brand style={{ color: props.kredits < 5 ? "red" : "white", paddingLeft: "1rem" }}>
          Credits: {props.kredits}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-navlinks" onClick={() => setShowModal(true)} />
        <Navbar.Collapse id="navbar-navlinks" >
          <Nav className="ms-auto" style={{ paddingRight: "1rem" }}>
          {showPurch && (
            
            <Nav.Link onClick={openLink} className="d-none d-md-block">
              <FaMoneyCheckAlt style={{ fontSize: "1.9em" }} />
            </Nav.Link>)}

            <Nav.Link onClick={() => window.open("https://theinterviewsimulator.com/privacy.html", '_blank')} className="d-none d-md-block">
              <BsQuestionOctagon style={{ fontSize: "1.9em" }} />
            </Nav.Link>

            <Nav.Link onClick={() => props.open()} className="d-none d-md-block">
              <VscFeedback style={{ fontSize: "1.9em" }} />
            </Nav.Link>
            <Nav.Link onClick={() => props.home()} className="d-none d-md-block">
              <FiSettings style={{ fontSize: "1.9em" }} />
            </Nav.Link>
            <Nav.Link onClick={() => props.delete()} className="d-none d-md-block">
              <VscSignOut style={{ fontSize: "1.9em" }} />
            </Nav.Link>
           
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav className="flex-column">
          {showPurch && (
          <Nav.Link onClick={() => { openLink(); handleCloseModal(); }}>
              <FaMoneyCheckAlt /> Purchase more
            </Nav.Link>)}
            <Nav.Link onClick={() => window.open("https://theinterviewsimulator.com/privacy.html", '_blank')}>
              <BsQuestionOctagon /> Privacy Policy
            </Nav.Link>
            <Nav.Link onClick={() => { props.open(); handleCloseModal(); }}>
              <VscFeedback /> Feedback
            </Nav.Link>
            <Nav.Link onClick={() => { props.home(); handleCloseModal(); }}>
              <FiSettings /> Settings
            </Nav.Link>
            <Nav.Link onClick={() => { props.delete(); handleCloseModal(); }}>
              <VscSignOut /> Sign out
            </Nav.Link>
          </Nav>
        </Modal.Body>
       
      </Modal>
    </Navbar>
  );
  };
  
export default MyNavbar;