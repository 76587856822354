
import { AiOutlineHome } from "react-icons/ai";
import Logopikku from "./logo.png";
import Flag from 'react-world-flags';

const MyNavbar2 = (props) => {
 
  const openLink = () => {
    if (props.lan){
      window.open('https://www.lassialbin.com/tyohaastattelusimulaattori', '_blank');
    }else{
      window.open('https://www.lassialbin.com/simulatorfree', '_blank');
    }
    
  };
  const openLink2 = () => {
    window.open('https://www.lassialbin.com/', '_blank');
  };
  return (
    <div className='FormNav' style={{display: "flex", justifyContent: "space-between"}}>
      
    <span onClick={() => openLink2()} className="FormHome2" style={{fontSize:"2.8vh", color:"#aaaaaa", marginLeft:"2vh", cursor:"pointer"}}>
        <img src={Logopikku} height={40} alt="Lassi Albin"></img>
    </span>
          
     <div>
      {props.lan ? 
      <span onClick={() => window.location.href ="https://theinterviewsimulator.com"} ><Flag code="us" className="flagForm" /></span>:
      <span onClick={() => window.location.href ="https://haastattelusimulaattori.com"} ><Flag code="fin" className="flagForm" /></span>}
      <span onClick={() => openLink()} className="FormHome" style={{fontSize:"2.8vh", color:"#aaaaaa", marginRight:"2vh", cursor:"pointer"}}>
        <AiOutlineHome/>
    </span>
    </div>
</div>
  );
  };
  
export default MyNavbar2;