import React, { useState } from 'react';
import Modal from 'react-modal';
Modal.setAppElement('#root');


const MyModal = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
    props.fin();
  };

 

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Esimerkki Modal"
        className="Feedback"
      >
        <span onClick={closeModal} style={{ float: 'right', cursor: 'pointer', fontWeight: '600', marginRight:"1.5vh",marginTop:"1vh"}}>
          X
        </span>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <h3>You have reached the account limit of 100 questions</h3>
          <p style={{color:"#2c83c8"}}>

          <a href="https://www.lassialbin.com/the-ai-powered-interview-simulator" 
            style={{ textDecoration: 'underline' }}
            onClick={() => {
                sessionStorage.removeItem('email');
                sessionStorage.removeItem('credits');
            }}
          >
            Purchase more 
          </a>
          </p>
        </div>
      </Modal>
    </div>
  );
}
export default MyModal;

